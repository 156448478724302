const socialMediaList = [
  {
    name: 'twitter',
    path: 'https://twitter.com/pokolm_',
  },
  {
    name: 'instagram',
    path: 'https://www.instagram.com/pokolmfraestechnik',
  },
  {
    name: 'linkedin',
    path: 'http://www.linkedin.com/company/6276941?trk=tyah&trkInfo=clickedVertical:company,clickedEntityId:6276941,idx:1-1-1,tarId:1470302164941,tas:Pokol',
  },
  {
    name: 'youtube',
    path: 'https://www.youtube.com/user/PokolmKnowHow',
  },
  {
    name: 'facebook',
    path: 'https://www.facebook.com/pokolm',
  },
  {
    name: 'xing',
    path: 'https://www.xing.com/pages/pokolm-fraestechnik-gmbh-co-kg',
  },
]

export default socialMediaList
