<template>
  <footer>
    <div class="top">
      <div class="logo-wrapper">
        <IconsLogoR mode="light" />
        <p>Premiumtools. We know how.</p>
      </div>
      <nuxt-link to="#@todo" class="contact-button">
        <AppButton inverted>Ansprechpartner finden</AppButton>
      </nuxt-link>
    </div>
    <div class="left">
      <nav>
        <ul>
          <li>
            <div class="language">Sprache <LanguageSwitch /></div>
          </li>
          <li>
            <nuxt-link :to="localePath({ name: 'impressum' })"
              >Impressum</nuxt-link
            >
          </li>
          <li>
            <nuxt-link :to="localePath({ name: 'datenschutz' })"
              >Datenschutz {{
            }}</nuxt-link>
          </li>
          <li>
            <nuxt-link :to="localePath({ name: 'agb' })">{{
              $t('navigation.agb')
            }}</nuxt-link>
          </li>
          <li>
            <nuxt-link :to="localePath({ name: 'suppliers' })">
              Lieferanten
            </nuxt-link>
          </li>
        </ul>
      </nav>
      <div class="contact-wrapper">
        <p class="address">
          <span class="name"> {{ contactData.name }} </span><br />
          {{ contactData.address.street }}<br />
          {{ contactData.address.zip }} {{ contactData.address.city }}
        </p>
        <p class="contact">
          Telefon:
          <a :href="'tel:' + contactData.phone" class="animated-link">{{
            contactData.phone
          }}</a
          ><br />
          Telefax: {{ contactData.fax }}<br />
          <a :href="'mailto:' + contactData.mail" class="animated-link">{{
            contactData.mail
          }}</a>
        </p>
      </div>
      <div class="social-media-wrapper">
        <a
          v-for="item of socialMediaList"
          :key="item.name"
          class="icon-wrapper"
          :href="item.path"
          target="_blank"
          rel="noopener"
        >
          <Icon :icon="item.name" />
        </a>
      </div>
    </div>
    <div class="right">
      <div class="brands">
        <p class="title">Unsere Marken</p>
        <div class="brands-wrapper">
          <nuxt-link
            v-for="item of brandList"
            :key="item.name"
            class="brand-wrapper"
            :to="item.route"
          >
            <Icon :icon="item.name" />
          </nuxt-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import contactData from '~/config/contact-data'
import socialMediaList from '~/config/social-media'
import brandList from '~/config/brands'

const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
footer {
  padding: $offset + $col-span;
  font-size: $font-18;
  color: $color-inverted;
  background: $color-black;
  @media (min-width: $bp-md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-block: $offset-md * 0.5;
    padding-inline: $offset-md;
  }
}
.top {
  margin-bottom: 3em;
  @media (min-width: $bp-md) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2em;
  }
}
.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 18.5em;
  margin-bottom: 5em;
  font-size: max(1.4rem, 1.25vw); // 18
  .logo {
    max-width: 26rem;
    margin-bottom: 1.5em;
  }
  p {
    font-size: inherit;
    font-weight: $weight-semibold;
  }
  @media (min-width: $bp-md) {
    flex-direction: row;
    justify-content: unset;
    max-width: unset;
    margin-bottom: 0;
    .logo {
      width: 10.25em;
      max-width: unset;
      margin-right: 2em;
      margin-bottom: 0;
    }
    p {
      margin-bottom: -0.65em;
    }
  }
}
.contact-button {
  @media (min-width: $bp-md) {
    width: 23%;
    min-width: 25rem;
    height: max-content;
  }
}
.button {
  width: 100%;
  max-width: 28rem;
  @media (min-width: $bp-md) {
    max-width: unset;
  }
}
.left {
  @media (min-width: $bp-md) {
    width: $col-span-md * 5 + $column-md * 0.5;
  }
}
nav {
  max-width: 24em;
  padding-bottom: 1.5em;
  margin-bottom: 3.25em;
  font-size: $font-12;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  li {
    min-width: $col-span * 4;
    margin-bottom: 2em;
  }
  .language {
    display: flex;
    align-items: center;
    .language-switch {
      margin-left: 0.5em;
    }
  }
  @media (min-width: $bp-md) {
    max-width: unset;
    margin-bottom: 2.5em;
    padding-top: 2.2em;
    padding-bottom: 1.2em;
    ul {
      justify-content: unset;
    }
    li {
      min-width: unset;
      margin-bottom: 1em;
      &:not(:last-of-type) {
        margin-right: 2em;
      }
    }
  }
}
.contact-wrapper {
  margin-bottom: 2.5em;
  line-height: 1.75;
  .address {
    max-width: 12em;
    margin-bottom: 2em;
    .name {
      font-weight: $weight-semibold;
    }
  }
  .contact {
    font-size: max(1.8rem, 1.25vw);
  }
  @media (min-width: 860px) {
    display: flex;
    .address {
      width: $col-span-md * 2;
      margin-right: $col-span-md;
      margin-bottom: unset;
    }
  }
}

.social-media-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 2.3em;
  justify-items: center;
  align-items: center;
  max-width: 15em;
  margin-bottom: 6em;
  .icon-wrapper {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 160%;
      height: 160%;
      top: -30%;
      left: -30%;
    }
    @media (hover: hover) {
      &:hover {
        svg {
          scale: 1.3;
        }
      }
    }
  }
  svg {
    transition: scale 0.2s;
    width: 2.25em;
    height: 2.25em;
  }
  @media (min-width: $bp-md) {
    display: flex;
    margin-bottom: 0;
    .icon-wrapper {
      margin-right: 2em;
    }
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.right {
  @media (min-width: $bp-md) {
    margin-top: 1.5em;
  }
}

.brands {
  //max-width: 24em;
  font-size: $font-12;
  .title {
    margin-bottom: 3em;
    padding-bottom: 3.25em;
    font-size: inherit;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  }
  .brands-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .brand-wrapper {
    width: $col-span * 4;
    margin-right: $column;
    margin-bottom: 2em;
  }

  svg {
    width: max-content;
    max-width: 100%;
    height: 1.2em;
  }
  @media (min-width: $bp-md) {
    .title {
      padding-top: 2.2em;
      padding-bottom: 2.2em;
    }
  }
  @media (min-width: $bp-tablet) {
    width: $col-span-md * 2 + $column-md;
    .brand-wrapper {
      width: $col-span-md;
      margin-right: $gutter-md;
    }
  }
}
</style>
