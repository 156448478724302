<template>
  <header
    :class="{
      inverted: isInverted,
      'mobile-open': isMobileNavigationOpen,
      'is-hidden': !isNavigationVisible && !arrivedState?.top,
      shrink: !arrivedState.top,
    }"
  >
    <NavigationMobileNavigation />
    <nav class="desktop">
      <component :is="isHomepage() ? 'h1' : 'div'">
        <nuxt-link class="logo" to="/">
          <IconsLogo
            spinnable
            :mode="!isInverted ? 'light' : ''"
            aria-hidden="true"
          />
          <span class="visually-hidden-focusable">Pokolm</span>
        </nuxt-link>
      </component>
      <ul class="navigation">
        <li v-for="el of navigationList" :key="el.name">
          <nuxt-link :to="localePath({ name: el.routeName })">
            <span>{{ el.title }}</span>
            <div
              :key="el.routeName"
              class="arrow-wrapper"
              :class="{ active: el.routeName === state.route.name }"
            >
              <Icon v-if="el.submenu" icon="chevron-right" />
            </div>
          </nuxt-link>
        </li>
      </ul>
      <LanguageSwitch />
    </nav>
  </header>
</template>

<script lang="ts" setup>
import navigationList from '~/config/navigation'
import { useRouter } from '#app'
import { reactive } from 'vue'
import { useScroll } from '@vueuse/core'
import { watch, watchEffect } from '@vue/runtime-core'

const router = useRouter()

const isMobileNavigationOpen = mobileNavigationIsOpen()
const isColorSchemeInverted = colorSchemeIsInverted()
const isHeaderColorSchemeInverted = headerColorSchemeIsInverted()
const isNavigationVisible = navigationIsVisible()

const state = reactive({
  route: router?.currentRoute,
})

const { y, directions, arrivedState } = useScroll(globalThis.document)

function setNavigationVisibility(visible: boolean) {
  isNavigationVisible.value = visible
}

const isInverted = computed(
  () =>
    isColorSchemeInverted.value ||
    (isHeaderColorSchemeInverted.value && arrivedState.top)
)

let scrollDirectionChangePos = 0

watchEffect(() => {
  if (!process.client) return

  setNavigationVisibility(
    y.value < scrollDirectionChangePos - window.innerHeight || arrivedState?.top
  )
})

watch(
  () => [directions.top, directions.bottom],
  () => {
    if (directions?.top) {
      if (!isNavigationVisible.value) {
        scrollDirectionChangePos = y.value
      }
    }
    if (directions?.bottom) {
      scrollDirectionChangePos = y.value
      setNavigationVisibility(false)
    }
  }
)
</script>

<style lang="scss" scoped>
header {
  transition: translate, padding, background;
  transition-duration: 0.25s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.4em $offset;
  z-index: 999;
  font-size: max(1.2rem, 0.834vw);
  @media (min-width: $bp-md) {
    padding: 1.4em $offset-md;
  }
}

.mobile-navigation {
  @media (min-width: $bp-lg) {
    display: none;
  }
}

:deep(h1) {
  font-size: unset;
}

.shrink {
  padding: 0 $offset;
  background: $color-text;
  @media (min-width: $bp-md) {
    padding: 0 $offset-md;
  }
}

.is-hidden {
  translate: 0 (-$header-height);
  @media (min-width: $bp-md) {
    translate: 0 (-$header-height-md);
  }
}

.inverted {
  .logo {
    color: black;
  }
}

nav {
  display: none;
  justify-content: space-between;
  align-items: center;
  font-weight: $weight-semibold;
  text-transform: uppercase;
  @media (min-width: $bp-lg) {
    display: flex;
  }
}
.logo {
  width: 9.75em;
}
.navigation {
  display: flex;
  grid-column-gap: 2em;
  color: $color-darkgray;
  @media (min-width: 1440px) {
    grid-column-gap: 3em;
  }
}
li {
  display: block;
  a {
    display: flex;
    align-items: center;
  }
  span {
    width: max-content;
  }
}
.arrow-wrapper {
  margin-left: 0.55em;
  svg {
    rotate: 90deg;
    width: 0.36em;
  }
}
.language-switch {
  color: $color-darkgray;
}
</style>
