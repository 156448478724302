<template>
  <div class="main-wrapper">
    <div v-if="state.testGrid" class="test">
      <div
        class="col"
        v-for="el of [...Array(12)]"
        :key="'test-col-' + el"
      ></div>
    </div>
    <AppHeader />
    <main>
      <slot />
      <div class="page-transition">
        <div class="spinner-wrapper">
          <!--          <LogoSpinner />-->
        </div>
      </div>
    </main>
    <AppFooter />

    <!-- Testing tools-->
    <TestingTools @grid="state.testGrid = !state.testGrid" />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from '#app'
import { watch, watchEffect } from '@vue/runtime-core'
import { reactive } from 'vue'
import { organizationData } from '~/config/seo-data'

const router = useRouter()
const route = useRoute()

const isMobileNavigationOpen = mobileNavigationIsOpen()
const isBodyFixed = bodyIsFixed()
const isHeaderColorSchemeInverted = headerColorSchemeIsInverted()

const state = reactive({
  route: router?.currentRoute,
  testGrid: false,
})

function preventSiteScroll(bodyIsFixed: boolean) {
  if (process.client) {
    const htmlElement = document.documentElement
    const className = 'no-scroll'
    if (bodyIsFixed) {
      htmlElement.classList.add(className)
    } else {
      htmlElement.classList.remove(className)
    }
  }
}

function closeMobileNavigation() {
  isMobileNavigationOpen.value = false
}

watch(
  () => state.route,
  () => {
    console.log('change')
    preventSiteScroll(false)
    closeMobileNavigation()

    // if (!route?.meta?.pageType) {
    //   isHeaderColorSchemeInverted.value = false
    //   return
    // }
    // isHeaderColorSchemeInverted.value = route?.meta?.pageType === 'dark'
  }
)

watch(
  () => route?.meta?.pageType,
  () => {
    if (!route?.meta?.pageType) {
      isHeaderColorSchemeInverted.value = false
      return
    }
    isHeaderColorSchemeInverted.value = route?.meta?.pageType === 'dark'
  },
  { immediate: true }
)

watchEffect(() => {
  preventSiteScroll(isBodyFixed.value)
})

// const head = useLocaleHead({
//   addDirAttribute: true,
//   identifierAttribute: 'id',
//   addSeoAttributes: true,
// })

// useHead({
//   title: 'pokolm',
//   titleTemplate: '%s | pokolm',
//   meta: [
//     { name: 'description', content: '%s' },
//     {
//       key: 'og:image',
//       property: 'og:image',
//       content: '/pokolm-logo.png',
//     },
//     {
//       key: 'og:title',
//       property: 'og:title',
//       content: '%s | pokolm',
//     },
//     {
//       key: 'og:description',
//       property: 'og:description',
//       content: '',
//     },
//   ],
//   script: () => [
//     { type: 'application/ld+json', json: JSON.stringify(organizationData) },
//   ],
// })
</script>

<style lang="scss" scoped>
.main-wrapper {
  transition: background 0.33s;
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  background: $color-gray;
  &.inverted {
    color: $color-inverted;
    background: $color-black;
  }
}
main {
  min-width: 0;
  min-height: 100vh;
}

// testing grid
.test {
  //mix-blend-mode: color-dodge;
  pointer-events: none;
  opacity: 0.2;
  position: fixed;
  display: flex;
  //grid-column-gap: $gutter;
  width: 100vw;
  height: 100vh;
  padding-inline: $offset;
  top: 0;
  left: 0;
  z-index: 999;
  background: blueviolet;
  .col {
    width: $column;
    background: $color-accent;
    &:not(:last-of-type) {
      margin-right: $gutter;
    }
  }
  @media (min-width: $bp-md) {
    //grid-column-gap: $gutter-md;
    padding-inline: $offset-md;
    .col {
      width: $column-md;
      &:not(:last-of-type) {
        margin-right: $gutter-md;
      }
    }
  }
}
</style>
