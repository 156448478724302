const brandList = [
  {
    name: 'finworx',
    route: '#',
  },
  {
    name: 'fourworx',
    route: '#',
  },
  {
    name: 'mirrorworx',
    route: '#',
  },
  {
    name: 'planworx',
    route: '#',
  },
  {
    name: 'quadworx',
    route: '#',
  },
  {
    name: 'slotworx',
    route: '#',
  },
  {
    name: 'spinworx',
    route: '#',
  },
  {
    name: 'uniworx',
    route: '#',
  },
  {
    name: 'uniworxplus',
    route: '#',
  },
  {
    name: 'waveworx',
    route: '#',
  },
]

export default brandList
