<template>
  <div class="testing-tools" :class="{ open: state.open }">
    <div class="button" @click="state.open = !state.open">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 800 800"
      >
        <path
          d="M756.2 320.9h-42.4c-7.9-30.7-20.2-59.5-36.2-85.8l30.5-30.6c8.2-8.2 12.8-19.2 12.8-30.9 0-11.6-4.6-22.6-12.8-30.9l-50.8-50.8c-16.5-16.5-45.3-16.5-61.7 0l-31.8 31.6c-26-15.2-53.9-26.9-84.7-34.4V43.9C479 19.8 459.8 0 435.8 0H364c-24.1 0-43 19.8-43 43.9v45.4c-30.7 7.5-58.8 19.2-84.9 34.4L204.3 92c-16.5-16.5-45.3-16.5-61.8 0l-50.8 50.8c-8.1 8.1-12.8 19.4-12.8 30.9 0 11.6 4.5 22.6 12.8 30.8l30.5 30.6c-16 26.3-28.3 55.1-36.2 85.8H43.5C19.4 320.9 0 340.3 0 364.3v71.8c0 24.1 19.4 43 43.5 43h42.4c7.9 30.7 20.2 59.7 36.2 86l-30.5 30.7c-8.2 8.2-12.8 19.2-12.8 30.9s4.5 22.7 12.8 30.9l50.8 50.8c8.2 8.2 19.2 12.8 30.8 12.8 11.6 0 22.6-4.5 30.9-12.8l31.9-31.6c26.1 15.2 54.2 26.9 84.9 34.4v45.3c0 24.1 18.9 43.5 43 43.5h71.8c24.1 0 43.3-19.4 43.3-43.5v-45.3c30.7-7.5 58.7-19.2 84.7-34.4l31.6 31.5c8.2 8.3 19.2 12.8 30.9 12.8s22.6-4.5 30.9-12.8l50.8-50.8c8.2-8.2 12.7-19.2 12.8-30.8 0-11.7-4.6-22.6-12.8-30.9L677.4 565c16-26.3 28.3-55.3 36.2-86H756c24.1 0 43.9-18.9 43.9-43v-71.8c.1-23.9-19.7-43.3-43.7-43.3zM399.8 683.1c-156.3 0-282.9-126.7-282.9-282.9s126.7-282.9 282.9-282.9 283 126.7 283 282.9-126.7 282.9-283 282.9z"
        />
      </svg>
    </div>
    <div v-if="state.open" class="menu">
      <div class="item grid" @click="$emit('grid')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 99 99"
        >
          <path
            d="M51 0H0v99h99V0H51zM3 3h45v45H3V3zm0 93V51h45v45H3zm93 0H51V51h45v45zM51 48V3h45v45H51z"
          />
        </svg>
      </div>
      <div
        class="item color-scheme"
        @click="isColorSchemeInverted = !isColorSchemeInverted"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="100%"
          width="100%"
        >
          <path
            fill="none"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 22v1.5M15 17.285V20c0 1.426-1.2 2-3 2s-3-.574-3-2v-2.715M12 17.197V13l-2-2"
          />
          <path
            d="M14.5 17.5a6.336 6.336 0 0 0 4-6A6.619 6.619 0 0 0 12 5a6.619 6.619 0 0 0-6.5 6.5 6.336 6.336 0 0 0 4 6ZM9 19.5h6M12 .5v2M1 10.5h2.291M3.5 3l2 2M23 10.5h-2.291M20.5 3l-2 2M12 13l2-2"
            fill="none"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  icon?: string
  inverted?: boolean
  external?: boolean
  red?: boolean
}>()

const state = reactive({
  open: false,
})

const isColorSchemeInverted = colorSchemeIsInverted()
</script>

<style lang="scss" scoped>
.testing-tools {
  opacity: 0.8;
  mix-blend-mode: hard-light;
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  bottom: 0.25em;
  right: 0.25em;
  z-index: 999;
  font-size: $font-18;
  &.open {
    opacity: 1;
    mix-blend-mode: unset;
  }
}
.button {
  cursor: pointer;
  padding: 0.5em;
  background: #ef7c8e;
  border-radius: 0.5em;
  &:hover {
    filter: brightness(0.9);
  }
}
svg {
  display: block;
  width: 1em;
  height: 1em;
}
.menu {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}
.item {
  cursor: pointer;
  padding: 0.5em;
  border-radius: 0.5em;
  &:hover {
    filter: brightness(0.9);
  }
}
.grid {
  background: #b6e2d3;
}
.color-scheme {
  background: #fae8e0;
}
</style>
