<template>
  <div class="language-switch">
    <div class="icon" @click="state.menuIsOpen = !state.menuIsOpen">
      <Icon icon="globe" />
      <Icon class="arrow" icon="chevron-right" />
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="state.menuIsOpen"
        class="menu"
        :class="{ 'top-menu': topMenu }"
      >
        <div
          v-for="lang in availableLocales"
          class="language-option"
          :class="lang"
          :key="lang"
        >
          <NuxtLink
            :to="switchLocalePath(lang)"
            :class="{ active: lang === locale }"
          >
            <span>{{ lang }}</span>
          </NuxtLink>
        </div>
      </div>
    </transition>
    <div
      v-if="state.menuIsOpen"
      class="overlay"
      @click="state.menuIsOpen = false"
    ></div>
  </div>
</template>

<script lang="ts" setup>
const { locale, availableLocales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const props = defineProps<{
  topMenu?: boolean
}>()

const state = reactive({
  menuIsOpen: false,
})
</script>

<style lang="scss" scoped>
.language-switch {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  height: max-content;
  font-size: $font-16;
  line-height: 1;
}
.icon {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  /*margin-right: 1em;*/
  &:after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
  }
  .globe {
    transition: stroke-width 0.33s;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    stroke: currentColor;
    stroke-width: 0;
  }
  .arrow {
    transition: margin 0.33s;
    rotate: 90deg;
    width: 0.3em;
  }
  @media (hover: hover) {
    &:hover {
      .globe {
        stroke-width: 0.25;
      }
      .arrow {
        margin-bottom: -0.25em;
      }
    }
  }
}
.menu {
  position: absolute;
  width: max-content;
  padding: 1em;
  top: 1.5em;
  left: -1em;
  z-index: 100;
  color: $color-text;
  background: white;
  box-shadow: 0 0.5em 0.3em rgba(0, 0, 0, 0.1);
  &.top-menu {
    top: unset;
    bottom: 2em;
  }
}
.language-option {
  cursor: pointer;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 0.5em;
  }
  > a {
    transition: font-weight 0.33s, color 0.33s;
    position: relative;
    font-weight: $weight-light;
    &:after {
      content: '';
      position: absolute;
      width: 200%;
      height: 160%;
      top: -30%;
      left: -50%;
    }
    &.active {
      font-weight: $weight-semibold;
      color: $color-primary;
    }
  }
  @media (hover: hover) {
    &:hover {
      > a {
        font-weight: $weight-semibold;
        color: $color-primary;
      }
    }
  }
}
</style>
