<template>
  <div class="burger-icon" :class="{ open }">
    <span class="top"></span>
    <span class="middle"></span>
    <span class="bottom"></span>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'

const props = defineProps<{
  open: boolean
}>()

const state = reactive({})
</script>

<style lang="scss" scoped>
.burger-icon {
  cursor: pointer;
  position: relative;
  width: 2.2em;
  height: 1.375em;
  font-size: $font-18;
  &:after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
  }
  &.open {
    color: $color-inverted;
    span {
      transition: translate 0.33s, rotate 0.33s, width 0.15s, color 0.33s;
    }
    .top {
      width: 50%;
      translate: calc(25% + 0.5px) calc(-100% - 0.5px);
      rotate: 45deg;
    }
    .middle {
      rotate: -45deg;
    }
    .bottom {
      width: 50%;
      translate: calc(-25% - 0.5px) calc(100% + 0.5px);
      rotate: 45deg;
    }
  }
}
span {
  transition: translate 0.33s, rotate 0.33s, width 0.15s 0.15s, color 0.33s;
  position: absolute;
  width: 100%;
  height: 2px;
  background: currentColor;
  border-radius: 2em;
  &.top {
    transform-origin: left;
    top: 0;
    left: 0;
  }
  &.middle {
    top: calc(50% - 1px);
  }
  &.bottom {
    transform-origin: right;
    right: 0;
    bottom: 0;
  }
}
</style>
