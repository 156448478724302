<template>
  <div class="mobile-navigation">
    <div class="top" :class="{ inverted: isInverted }">
      <component :is="isHomepage() ? 'h1' : 'div'">
        <nuxt-link class="logo" to="/">
          <IconsLogo
            spinnable
            :mode="!isInverted && !isMobileNavigationOpen ? 'light' : ''"
            aria-hidden="true"
          />
          <span class="visually-hidden-focusable">Pokolm</span>
        </nuxt-link>
      </component>

      <NavigationBurgerIcon
        @click.native="toggleMobileNavigation"
        :open="isMobileNavigationOpen"
      />
    </div>

    <transition name="fade" mode="out-in">
      <nuxt-img
        v-if="isMobileNavigationOpen"
        class="background"
        src="/img/backgrounds/placeholder-mobile-navigation-background.webp"
      />
    </transition>

    <transition name="fade" mode="out-in">
      <nav v-if="isMobileNavigationOpen">
        <ul class="navigation">
          <li v-for="el of navigationList" :key="el.name">
            <nuxt-link :to="localePath({ name: el.routeName })">
              <span>{{ el.title }}</span>
              <div
                :key="el.routeName"
                class="arrow-wrapper"
                :class="{
                  active: el.routeName === state.route.name,
                  sub: el.submenu,
                }"
              >
                <Icon icon="chevron-right" />
              </div>
            </nuxt-link>
          </li>
          <LanguageSwitch top-menu />
        </ul>
      </nav>
    </transition>
  </div>
</template>

<script setup>
import navigationList from '~/config/navigation'
import { useRouter } from '#app'
import { reactive } from 'vue'

const router = useRouter()

const isMobileNavigationOpen = mobileNavigationIsOpen()
const isBodyFixed = bodyIsFixed()
const isColorSchemeInverted = colorSchemeIsInverted()
const isHeaderColorSchemeInverted = headerColorSchemeIsInverted()

const state = reactive({
  route: router?.currentRoute,
})

const isInverted = computed(
  () => isColorSchemeInverted.value || isHeaderColorSchemeInverted.value
)

function toggleMobileNavigation() {
  isMobileNavigationOpen.value = !isMobileNavigationOpen.value
  isBodyFixed.value = isMobileNavigationOpen.value
}
</script>

<style lang="scss" scoped>
.mobile-navigation {
  transition: translate, padding, background;
  transition-duration: 0.25s;
  width: 100%;
  padding: 1.4em 0;
  z-index: 999;
  font-size: max(1.2rem, 0.834vw);
}

.top {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  color: $color-text;
  &.inverted {
    color: $color-inverted;
  }
}

.logo {
  width: 9.75em;
}

.background {
  position: fixed;
  object-fit: cover;
  height: 100%;
  inset: 0;
}

nav {
  overflow: auto;
  position: fixed;
  min-height: 100vh;
  inset: 0;
}

.navigation {
  display: flex;
  flex-direction: column;
  padding-inline: $offset;
  padding-block: $header-height * 2;
  font-size: max(2rem, 1.39vw); // 20
  color: $color-primary;
}
li {
  display: block;
  margin-bottom: 1em;
  a {
    display: flex;
    align-items: center;
    @media (hover: hover) {
      &:hover {
        .arrow-wrapper {
          &.sub {
            svg {
              translate: 0 2px;
            }
          }
        }
        svg {
          translate: 2px 0;
        }
      }
    }
  }
  span {
    width: max-content;
  }
}
.arrow-wrapper {
  margin-left: 0.55em;
  color: $color-inverted;
  &.sub {
    svg {
      rotate: 90deg;
    }
  }
  svg {
    transition: translate 0.33s;
    width: 0.275em;
  }
}
:deep(.language-switch) {
  margin-top: 2em;
  margin-right: $offset;
  margin-left: auto;
  color: $color-inverted;
  .globe {
    width: 1.5em;
    height: 1.5em;
  }
}
</style>
